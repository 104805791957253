<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="mb-4 p-4">
            <b-form-group class="mb-2 w-25">
                <b-input-group>
                    <b-form-input
                        size="sm"
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                        <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <div>
                <div class="row border">
                    <div class="col-3 bg-light" v-for="field in fields" :key="field.key">
                        <div class="p-2" style="font-weight: bold">{{ field.label }}</div>
                    </div>
                </div>
                <div class="row border" v-for="(item, idx) in items" :key="item.id">
                    <div class="col-3" v-for="field in fields" :key="field.key">
                        <div class="p-2">
                            <span v-if="field.key === 'action'">
                                <b-button v-b-toggle="`accordion-${idx}`" variant="primary" size="sm"
                                    >Toggle Details</b-button
                                >
                            </span>
                            <span v-else>
                                {{ item[field.key] }}
                            </span>
                        </div>
                    </div>
                    <b-collapse :id="`accordion-${idx}`" class="w-100">
                        <div class="px-3 pb-2">
                            <h6 class="mt-2 ml-2">Customers Screening Records</h6>
                            <b-table
                                style="max-height: 400px; width: 100%"
                                :items="item.customerScreeningRecords"
                                :fields="detailsFields"
                                small
                                responsive
                                bordered
                                head-variant="light"
                                hover
                            >
                                <template v-slot:cell(action)="data">
                                    <b-button @click="showModal(data.item)" variant="primary" size="sm"
                                        >Edit customer</b-button
                                    >
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-card>

        <!-- Modal -->
        <b-modal id="modal-view" centered title="Edit customer" v-model="show" @hide="show = false" hide-footer>
            <div class="pb-2" v-if="customerSelected">
                <h4 class="mail-desc d-block">{{ customerSelected.customerName }}</h4>
            </div>
            <b-form @submit.prevent="saveAmount">
                <b-form-group label="Amount" label-class="fw-medium mb-1" label-for="amount">
                    <b-form-input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        required
                        v-model="amount"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Monthly Payment" label-class="fw-medium mb-1" label-for="monthly">
                    <b-form-input
                        id="monthly"
                        type="number"
                        placeholder="0.00"
                        required
                        v-model="monthlyPayment"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Comment" label-class="fw-medium mb-1" label-for="comment">
                    <b-form-input
                        id="comment"
                        type="text"
                        placeholder="Comment here"
                        v-model="comment"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button block :disabled="loadingSave" variant="primary" type="submit">
                    <b-spinner small v-if="loadingSave"></b-spinner>
                    <span v-else>Save</span>
                </b-button>
            </b-form>
        </b-modal>
    </b-overlay>
</template>

<script>
export default {
    name: 'applications',

    data: () => ({
        page: {
            title: 'applications',
        },
        fields: [
            { key: 'bankId', label: 'ID' },
            { key: 'bankName', label: 'Bank Name' },
            { key: 'bankEmail', label: 'bankEmail' },
            { key: 'action', label: 'Action', isRowHeader: true },
        ],
        detailsFields: [
            { key: 'applicationCode', label: 'Application Code' },
            { key: 'customerType', label: 'CustomerType' },
            { key: 'nationalId', label: 'National ID' },
            { key: 'customerName', label: 'Customer Name' },
            { key: 'amount', label: 'Amount' },
            { key: 'action', label: 'Action' },
        ],

        loading: false,
        loadingSave: false,
        amount: null,
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        customerSelected: null,
        show: false,
        comment: null,
        monthlyPayment: null,

        items: [],
        totalRows: 0,
    }),
    components: {},
    methods: {
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('customer/screening/get_screeningVerifications');
            this.items = response.dataResult;
            this.totalRows = response.dataRecords;
            this.loading = false;
        },
        showModal(item) {
            this.customerSelected = item;
            this.amount = item.amount;
            this.monthlyPayment = item.montlyPayment;
            this.comment = item.comment;
            this.show = true;
        },
        async saveAmount() {
            this.loadingSave = true;
            let data = {
                Id: this.customerSelected.id,
                GUID: this.customerSelected.guid,
                Amount: this.amount,
                ModifiedBy: 1,
                comment: this.comment,
                montlyPayment: this.monthlyPayment,
            };

            await this.$store.dispatch('customer/screening/update_fromVerificationForm', data);
            await this.getData();
            this.amount = null;
            this.show = false;
            this.loadingSave = false;
        },
        clearData() {
            this.customerSelected = null;
        },
    },
    computed: {},
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
